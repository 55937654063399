
import VaultsData from "hpay/content/Vaults.json";
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Button, Card, Col, Modal, Row } from 'react-bootstrap';
import CountUp from 'react-countup';
import NetworkContext from '../../../context/network-context';
import { fetchTokenBalance } from '../../../web3/account';
import { useFaucet } from '../../../web3/web3';
import VaultInput from "../common/vault-input";
import { CLAIM_TEST_TOKENS } from "gatsby-env-variables";

function VaultStats({ address, isWhitelisted, vaultData, lock, unlock, claim, compound, userLocked, userEarned, userUnlockTime, isUnlocked, userEarnedReflection, relock }) {
    const { account, connected, isCorrectNetwork, pendingTransaction, executeTransaction } = useContext(NetworkContext);

    const { mint } = useFaucet(vaultData && vaultData.tokenAddress);
    const [showStakingModal, setShowStakingModal] = useState(false);
    const [modalStatus, setModalStatus] = useState(null);


    const handleClose = () => {
        if (pendingTransaction) {
            return;
        }
        setShowStakingModal(false);
    };
    const [vault, setVault] = useState(null);
    const [allowEarlyUnlock, setAllowEarlyUnlock] = useState(false);

    const [status, setStatus] = useState(0);
    const [balance, setBalance] = useState(0);

    useEffect(() => {
        if (!vaultData) {
            return;
        }

        setStatus(vaultData.started);
        setAllowEarlyUnlock(vaultData.allowEarlyUnlock);
        setBalance(0);
    }, [vaultData]);


    useEffect(() => {
        setVault(VaultsData[address]);
    }, [address]);


    const fetchBalance = useCallback(async () => {
        if (!vaultData) {
            setBalance(0);
            return;
        }
        const balance = await fetchTokenBalance(vaultData.stakeToken, account, vaultData.chainId);
        setBalance(balance || 0);
    }, [setBalance, vaultData, account]);


    useEffect(() => {
        const load = async () => {
            if (!account || !connected) {
                return;
            }
            await fetchBalance();
        };
        load();
    }, [account, connected, vaultData, fetchBalance]);


    const handleStake = useCallback(() => {
        if (!vault) {
            return;
        }

        setModalStatus({
            mode: 'stake',
            isUnlocked,
            action: async (amount) => {
                const tx = async () => await lock(amount);
                try {
                    await executeTransaction({
                        message: 'Staking',
                        tx
                    });

                    await fetchBalance();
                } catch (error) {
                    console.log(error);
                }

                setShowStakingModal(false);
            }
        });

        setShowStakingModal(true);
    }, [setModalStatus, setShowStakingModal, executeTransaction, lock, isUnlocked, fetchBalance, vault]);

    const handleUnstake = useCallback(() => {
        setModalStatus({
            mode: 'unstake',
            isUnlocked,
            userUnlockTime,
            action: async (amount) => {
                const tx = async () => {
                    return await unlock(amount);
                };
                try {
                    await executeTransaction({
                        message: 'Withdrawing',
                        tx
                    });

                    await fetchBalance();

                } catch (error) {
                    console.log(error);
                }

                setShowStakingModal(false);
            }
        });

        setShowStakingModal(true);
    }, [setModalStatus, setShowStakingModal, unlock, executeTransaction, fetchBalance, isUnlocked, userUnlockTime]);

    const handleCompound = useCallback(async () => {
        const tx = async () => {
            return await compound();
        };
        try {
            await executeTransaction({
                message: 'Compunding',
                tx
            });

            await fetchBalance();
        } catch (error) {
            console.log(error);
        }
    }, [compound, executeTransaction, fetchBalance]);

    const handleFaucet = useCallback(async () => {
        if (!isCorrectNetwork) {
            return;
        }
        const tx = async () => {
            return await mint()
        };
        try {
            await executeTransaction({
                message: 'Minting Tokens',
                tx
            });

            await fetchBalance();
        } catch (error) {
            console.log(error);
        }
    }, [mint, executeTransaction, fetchBalance, isCorrectNetwork]);

    const handleClaim = useCallback(async () => {
        const tx = async () => {
            return await claim();
        };
        try {
            await executeTransaction({
                message: 'Claiming',
                tx
            });

            await fetchBalance();
        } catch (error) {
            console.log(error);
        }
    }, [claim, executeTransaction, fetchBalance]);

    return (
        <>
            <div className="staking-component h-100">
                <Card>
                    <Card.Body>
                        <div>
                            <Row >
                                <Col sm={6} md={12} lg={6}
                                    className="farming-stats-group  order-md-0 order-sm-2 order-lg-2 d-flex justify-content-end">

                                    {
                                        vault && vault.type !== 'AUTOCOMPOUND_RELOCK' ?
                                            <>
                                                <div>
                                                    <Button onClick={handleClaim}
                                                        disabled={!connected || !isCorrectNetwork || !!pendingTransaction || userEarned === 0}
                                                        className="text-white">Claim</Button>
                                                </div>
                                                {vault && vault.compound && <div className='ms-2'>
                                                    <Button
                                                        onClick={handleCompound}
                                                        disabled={!connected || !isCorrectNetwork || !!pendingTransaction || userEarned === 0}
                                                        className="text-white">Compound</Button>
                                                </div>}
                                            </> :
                                            <Button onClick={relock}
                                                disabled={!connected || !isCorrectNetwork || !!pendingTransaction || !isUnlocked || userLocked === 0}
                                                className="text-white">
                                                Relock Tokens
                                            </Button>
                                    }
                                </Col>

                                <Col sm={6} md={12} lg={6} className="farming-stats-group text-start ">
                                    <small className="farming-stats-value-label">You Earned</small>
                                    <p className="farming-stats-value-item mb-0">
                                        <CountUp
                                            isCounting end={userEarned || 0}
                                            decimals={2} separator=',' /> {vault && vault.ticker}
                                    </p>
                                    {vault && vault.reflections && connected && <small className="text-primary-gradient">+ {userEarnedReflection && userEarnedReflection.toFixed(2)} {vault.reflections.ticker} Reflections</small>}
                                </Col>
                            </Row>

                            <Row >
                                <Col className="farming-stats-group text-start">
                                    <small className="farming-stats-value-label">Total {vault && vault.ticker} Locked</small>
                                    <p className="farming-stats-value-item mb-0 text-primary-gradient" >
                                        <CountUp isCounting end={(vaultData && vaultData.totalLocked) || 0} decimals={2} separator=',' />
                                    </p>
                                </Col>

                                {vault && vault.type !== 'AUTOCOMPOUND_RELOCK' ? <Col className="farming-stats-group text-end">
                                    <small className="farming-stats-value-label">Total {vault && vault.ticker} Rewards</small>
                                    <p className="farming-stats-value-item text-primary-gradient">
                                        <CountUp isCounting end={(vaultData && vaultData.totalRewardFund) || 0} decimals={2} separator=',' />
                                    </p>
                                </Col>
                                    :
                                    <Col className="farming-stats-group text-end">
                                        <small className="farming-stats-value-label"> Relock Bonus </small>
                                        <p className="farming-stats-value-item text-primary-gradient">
                                            <CountUp isCounting end={(vaultData && +vaultData.relockBonus) || 0} decimals={2} separator=',' />%
                                        </p>
                                    </Col>}

                            </Row>



                            <Row className="justify-content-between align-items-center mt-2 ">
                                {isWhitelisted ?
                                    <>
                                        <Col sm={6} className="mb-sm-0 mb-3">
                                            <Button
                                                onClick={handleStake}
                                                disabled={!connected || !isCorrectNetwork || !!pendingTransaction || !vaultData || status === 2}
                                                className="text-white w-100" >Stake</Button>
                                        </Col>
                                        <Col sm={6} >
                                            <Button
                                                onClick={handleUnstake}
                                                disabled={!connected || !isCorrectNetwork || !!pendingTransaction || +userLocked === 0 || (!allowEarlyUnlock && !isUnlocked)}
                                                className="text-white w-100" > Unstake </Button>
                                        </Col>
                                    </> : <p className='m-auto'> Your address is not allowed to stake </p>}

                            </Row>

                            {CLAIM_TEST_TOKENS && <Row className="justify-content-between align-items-center mt-2 ">

                                <span
                                    role="button"
                                    tabIndex={0}
                                    onKeyPress={handleFaucet}
                                    onClick={handleFaucet}
                                    disabled={!connected || !isCorrectNetwork || !!pendingTransaction || !vaultData}
                                    className="clickable mx-auto w-100" >Claim Test Tokens</span>

                            </Row>}
                        </div>
                    </Card.Body>
                </Card >

            </div >

            <Modal
                className="stake-modal"
                aria-labelledby="contained-modal-title-vcenter"
                centered show={!!showStakingModal} onHide={handleClose} >

                <Modal.Body>
                    <VaultInput userLocked={userLocked} vault={vaultData} {...modalStatus} balance={balance}></VaultInput>
                </Modal.Body>

            </Modal>

        </>
    );
}

export default VaultStats;
