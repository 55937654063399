
import VaultsData from "hpay/content/Vaults.json";
import React, { useCallback, useEffect, useState } from 'react';
import { Col, OverlayTrigger, Row, Tooltip } from "react-bootstrap";
import Reddit from '../../../images/inline/reddit.inline.svg';
import Telegram from '../../../images/inline/telegram-icon.inline.svg';
import Twitter from '../../../images/inline/twitter.inline.svg';


function VaultHeader({ address, Stats }) {
    const [vault, setVault] = useState(null);

    useEffect(() => {
        setVault(VaultsData[address]);
    }, [address]);


    const SocialMedia = useCallback(() => (<div className='ms-auto align-self-start'>
        <OverlayTrigger
            overlay={
                <Tooltip id={`tooltip-telegram`}>
                    <strong>Telegram</strong>.
                </Tooltip>
            }>
            <a target="_blank" rel="noopener noreferrer"
                href={vault && vault.telegram}>
                <Telegram className="footer-icon" />
            </a>
        </OverlayTrigger>

        {vault && vault.reddit && <OverlayTrigger
            overlay={
                <Tooltip id={`tooltip-telegram`}>
                    <strong>Reddit</strong>.
                </Tooltip>
            }>
            <a target="_blank" rel="noopener noreferrer"
                href={vault && vault.reddit}>
                <Reddit className="footer-icon" />
            </a>
        </OverlayTrigger>}

        <OverlayTrigger
            overlay={
                <Tooltip id={`tooltip-twitter`}>
                    <strong>Twitter</strong>.
                </Tooltip>
            }>
            <a target="_blank" rel="noopener noreferrer"
                href={vault && vault.twitter}>

                <Twitter className="footer-icon" />
            </a>
        </OverlayTrigger>
    </div>), [vault]);

    return (
        <div className="reward-claim-component">
            <Row className="mb-lg-5 mb-4 reward-header">
                <Col md={8}>

                    <div className="d-flex justify-content-between">
                        <div className='d-flex align-items-center'>
                            <img
                                style={{ borderRadius: '50%', alignSelf: 'baseline', marginTop: '1rem', marginRight: '0.5rem' }}
                                src={'/vaults/' + (vault && vault.imagePath)}
                                alt="vault"
                                width={60}
                                height={60}
                            />
                            <h2 style={{ lineHeight: '0.8' }} className="title-seperator ms-2">{vault && vault.owner} <br />
                                <small className='text-primary-gradient' style={{ fontSize: '1.2rem' }}>Time Locked Staking</small></h2>

                        </div>
                        <div className='d-flex flex-column justify-content-left text-center'>
                            <a target="_blank" rel="noopener noreferrer" className='mb-2'
                                href={vault && vault.website}>
                                Visit Website
                            </a>
                            {SocialMedia}
                        </div>
                    </div>
                    <Col md={12}>
                        <p className='text-justify '>
                            {vault && vault.description}
                        </p>
                    </Col>
                </Col>

                <Col md={4} className=" d-flex align-items-center  justify-content-end text-center bounty-widget flex-wrap m-3 m-md-0">
                  {Stats}
                </Col>
            </Row>
        </div >
    );
}

export default VaultHeader;
