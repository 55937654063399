
import React, { useEffect, useState } from 'react';
import { Badge, Card, Col, Row } from 'react-bootstrap';
import Countdown from 'react-countdown';
import CountUp from 'react-countup';
import VaultsData from "hpay/content/Vaults.json";

import { useVaultAddress } from '../../../utils/utils';
import { useCurrentVault, useRefreshCurrentVault, useUserVaultAmounts } from '../../../state/flash-staking';

const statusMapping = {
    0: "Upcoming",
    1: "Staking Live",
    2: "Round Started",
    3: "Ended"
};


function VaultInfo({ address }) {
    const vaultAddress = useVaultAddress(address);
    const [vault, setVault] = useState(null);
    const [roundFill, setRoundFill] = useState(0);

    const { currentVault: vaultData } = useCurrentVault(vaultAddress);
    const [, refreshVault] = useRefreshCurrentVault(vaultAddress);
    const { userLocked, userPending } = useUserVaultAmounts(vaultAddress);

    const [status, setStatus] = useState(statusMapping[0]);
    useEffect(() => {
        if (!vaultData) {
            return;
        }
        setRoundFill((vaultData.totalLocked * 100) / vaultData.maxLock);
        setStatus(statusMapping[vaultData.status]);
    }, [vaultData]);

    useEffect(() => {
        setVault(VaultsData[vaultAddress]);
    }, [vaultAddress]);



    const headerText = <small>
        {
            !vaultData && <>
                Loading...
            </>
        }
        {
            vaultData && vaultData.status === 0 && <>
                <span className="text-primary-gradient">{vault && vault.ticker}</span> flash staking will start soon
            </>
        }

        {
            vaultData && vaultData.status === 1 && <>
                Lock your <span className="text-primary-gradient">{vault && vault.ticker}</span> to earn
            </>
        }

        {
            vaultData && vaultData.status === 2 && <>
                All staked <span className="text-primary-gradient">{vault && vault.ticker}</span> is locked until round ends
            </>
        }

        {
            vaultData && vaultData.status === 3 && <>
                <span className="text-primary-gradient">{vault && vault.ticker}</span> flash staking has ended
            </>
        }
    </small>;

    const timerText = <small className="farming-stats-value-label">

        {
            !vaultData && <>
                Starts In
            </>
        }
        {
            vaultData && vaultData.status === 0 && <>
                Starts In
            </>
        }

        {
            vaultData && vaultData.status === 1 && <>
                Locks In
            </>
        }

        {
            vaultData && vaultData.status === 2 && <>
                Ends In
            </>
        }

        {
            vaultData && vaultData.status === 3 && <>
                Ended
            </>
        }
    </small>;


    return (
        <div className="reward-claim-component">
            <Card>
                <Card.Body className="d-flex flex-column justify-content-between">
                    <div className="d-flex  justify-content-between align-items-center flex-grow-1" style={{ marginBottom: '2.25rem' }}>

                        <div>
                            <h3 className='mb-0'>Round Info</h3>
                            {
                                headerText
                            }

                        </div>
                        <div >

                            <Badge pill variant={'primary'} >
                                <span className="text-primary-gradient">
                                    {status}
                                </span>
                            </Badge>
                        </div>

                    </div>

                    <div className="w-100">
                        <Row >
                            <Col className="farming-stats-group text-start">
                                <small className="farming-stats-value-label">Maximum Lock</small>
                                <p className="farming-stats-value-item mb-0">
                                    <CountUp isCounting end={(vaultData && vaultData.maxLockPerWallet) || 0} decimals={0} preserveValue={true} separator=',' />  {vault && vault.ticker}
                                </p>
                            </Col>

                            <Col className="farming-stats-group text-end">
                                <small className="farming-stats-value-label">APR </small>
                                <p className="farming-stats-value-item">
                                    <small>%</small><CountUp isCounting end={(vaultData && vaultData.return) || 0} decimals={0} preserveValue={true} separator=',' />
                                </p>
                            </Col>
                        </Row>
                        <Row >
                            <Col md={6} className="farming-stats-group text-md-start text-end ">
                                <small className="farming-stats-value-label">You Locked </small>
                                <p className="farming-stats-value-item mb-0">
                                    <CountUp isCounting end={userLocked || 0} decimals={2} preserveValue={true} separator=',' /> {vault && vault.ticker}
                                </p>
                            </Col>

                            <Col md={6} className="farming-stats-group text-end">
                                <small className="farming-stats-value-label">Pending  Rewards </small>
                                <p className="farming-stats-value-item">
                                    <CountUp isCounting end={userPending || 0} decimals={0} preserveValue={true} separator=',' />  {vault && vault.ticker}
                                </p>
                            </Col>
                        </Row>

                        <Row >
                            <Col className="farming-stats-group text-start  mb-0 ">

                                {timerText}
                                <p className="farming-stats-value-item text-primary-gradient">
                                    <Countdown onComplete={() => {
                                        setTimeout(() => {
                                            refreshVault();
                                        }, 5000);
                                    }} key={(vaultData && vaultData.time)} date={(vaultData && vaultData.time)}></Countdown>
                                </p>
                            </Col>

                            <Col className="farming-stats-group text-end mb-0">
                                <small className="farming-stats-value-label">Round Fill </small>
                                <p className="farming-stats-value-item">
                                    <CountUp isCounting end={roundFill} decimals={2} preserveValue={true} separator=',' /> %
                                </p>
                            </Col>
                        </Row>
                    </div>
                </Card.Body>
            </Card >
        </div >
    );
}

export default VaultInfo;
