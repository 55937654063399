
import VaultsData from "hpay/content/Vaults.json";
import React, { useEffect, useState } from 'react';
import { Badge, Card, Col, Row } from 'react-bootstrap';
import CountUp from 'react-countup';
import { useCurrentVault, useUserVaultAmounts } from '../../../state/vault-staking';
import { useVaultAddress } from '../../../utils/utils';

const statusMapping = {
    0: "Upcoming",
    1: "Staking Live",
    2: "Staking Live",
    3: "Ended"
};


function VaultInfo({ address }) {
    const vaultAddress = useVaultAddress();
    const { currentVault: vaultData } = useCurrentVault(vaultAddress);
    const { userLocked } = useUserVaultAmounts(vaultAddress);
    const [vault, setVault] = useState(null);

    const [status, setStatus] = useState(statusMapping[0]);
    useEffect(() => {
        if (!vaultData) {
            return;
        }
        // console.log("Vault data", vaultData)
        setStatus(statusMapping[vaultData.status]);
    }, [vaultData]);

    useEffect(() => {
        setVault(VaultsData[address]);
    }, [address]);



    const headerText = <small>
        {
            !vaultData && <>
                Loading...
            </>
        }
        {
            vaultData && vaultData.status === 0 && <>
                <span className="text-primary-gradient">{vault && vault.ticker}</span> staking will start soon
            </>
        }

        {
            vaultData && vaultData.status >= 1 && <>
                Stake your <span className="text-primary-gradient">{vault && vault.ticker}</span> to earn
            </>
        }


    </small>;

    return (
        <div className="reward-claim-component ">
            <Card>
                <Card.Body className="d-flex flex-column justify-content-between">
                    <div className="d-flex  justify-content-between  " >

                        <div className='farming-stats-group'>
                            <p className="farming-stats-value-item mb-0">Staking Info</p>
                            {
                                headerText
                            }

                        </div>
                        <div >

                            <Badge pill variant={'primary'} >
                                <span className="text-primary-gradient">
                                    {status}
                                </span>
                            </Badge>
                        </div>

                    </div>

                    <div className="w-100">
                        <Row >

                            <Col md={6} className="farming-stats-group text-md-start text-end mb-2">
                                <small className="farming-stats-value-label">You staked </small>
                                <p className="farming-stats-value-item mb-0">
                                    <CountUp isCounting end={userLocked || 0} decimals={2} preserveValue={true} separator=',' /> {vault && vault.ticker}
                                </p>
                            </Col>
                            <Col className="farming-stats-group text-end mb-2">
                                <small className="farming-stats-value-label">APR </small>
                                <p className="farming-stats-value-item">
                                    <CountUp isCounting end={(vaultData && vaultData.return) || 0} decimals={0} preserveValue={true} separator=',' />%
                                </p>
                            </Col>


                        </Row>
                    </div>
                </Card.Body >
            </Card >
        </div >
    );
}

export default VaultInfo;
